import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import {Wait} from './wait/Wait';
import {FinalPage} from './access/FinalPage';
import {SignIn} from './access/SignIn';
import {SignOut} from './access/SignOut';
import {CovidHealthDeclaration} from './covid-health-declaration/CovidHealthDeclaration';
import {Messages} from './message/Messages';
import {OnsiteNowReport} from './report/OnsiteNowReport';
import {CovidNotPermitted} from './covid-health-declaration/CovidNotPermitted';

export const Main = () => (
    <Container className="flex-fill main" fluid={true}>
        <Switch>
            <Route path="/" exact>
                <Wait/>
            </Route>
            <Route path="/covid-health-declaration">
                <CovidHealthDeclaration/>
            </Route>
            <Route path="/covid-not-permitted">
                <CovidNotPermitted/>
            </Route>
            <Route path="/final-page">
                <FinalPage/>
            </Route>
            <Route path="/messages">
                <Messages/>
            </Route>
            <Route path="/onsite-now">
                <OnsiteNowReport/>
            </Route>
            <Route path="/sign-in">
                <SignIn/>
            </Route>
            <Route path="/sign-out">
                <SignOut/>
            </Route>
        </Switch>
    </Container>
);
