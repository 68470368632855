import {RestClient} from './RestClient';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {LogpointEvent} from '../models/enums/LogpointEvent';
import {Direction} from '../models/enums/Direction';
import {IContext} from '../models/interfaces/IContext';
import {IEventData} from '../models/interfaces/IEventData';

export class EventLogService {
    private restClient = new RestClient();

    public logScreenAbandonedEvent(context: IContext, history: any): void {
        let eventId;
        switch (history.location.pathname) {
            case '/breathalyser':
                eventId = LogpointEvent.ABORT_ALCOLIZER_READING;
                break;
            case '/camera':
                eventId = LogpointEvent.ABORT_PHOTO;
                break;
            case '/fit-for-work':
            case '/covid-health-declaration':
                eventId = LogpointEvent.ABORT_FIT_FOR_WORK;
                // TODO: What is LogpointEvent.ABORT_FIT_FOR_WORK_OFFLINE_ANON?
                break;
            case '/induction':
                eventId = LogpointEvent.ABORT_FLASH_INDUCTION;
                break;
            case '/induction-confirmation':
                eventId = LogpointEvent.ABORT_INDUCTION_CONFIRMATION;
                break;
            case '/messages':
                eventId = LogpointEvent.ABORT_MESSAGES;
                break;
            case '/welcome':
                eventId = LogpointEvent.ABORT_WELCOME;
                break;
            default:
                eventId = LogpointEvent.ABORT_GENERAL_PAGE_FLOW;
                break;
        }

        const data = `Worker abandoned screen during ${context.direction === Direction.ENTRY ? 'login' : 'logout'}.`;
        const eventLogRequest = EventLogService.createLogEvent(eventId, context.worker?.id as number, data);

        this.logEvent(eventLogRequest);
    }

    private static createLogEvent(id: LogpointEvent, workerId: number, data: string): IEventData {
        return {
            id: id,
            workerId: workerId,
            data: data
        } as IEventData;
    }

    private logEvent(eventData: IEventData): Observable<any> {
        return fromPromise(this.restClient.doCall('/api/v1/softpoint/event', 'POST',
            eventData) as Promise<any>);
    }
}
