import * as React from 'react';
import {Alert, Col, Row, Spinner} from 'reactstrap';

export const PegasusSpinner = (props: { message: string, hidden: boolean }) => {
    return (
        <div hidden={props.hidden}>
            <div className="position-absolute vh-100 vw-100 spinner-background"/>
            <div className="position-absolute pegasus-spinner">
                <Alert color="dark">
                    <Row className="py-3">
                        <Col sm={4} className="text-right">
                            <Spinner type="border" size="lg" color="dark"/>
                        </Col>
                        <Col sm={8}>{props.message}</Col>
                    </Row>
                </Alert>
            </div>
        </div>
    )
};
