import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/pro-light-svg-icons/faSignInAlt';
import {faSignOutAlt} from '@fortawesome/pro-light-svg-icons/faSignOutAlt';

export const SignInSignOutButtons = () => {
    return (
        <Row className="ml-4">
            <Col sm={12} className="mb-4">
                <Link to="/sign-in" className="btn btn-primary btn-logpoint btn-sign-in-out w-75 text-left float-left">
                    <FontAwesomeIcon icon={faSignInAlt} size="3x" className="h-100 mx-4"/>
                    <span className="pl-2 font-weight-bold position-absolute">Sign In</span>
                </Link>
            </Col>
            <Col sm={12}>
                <Link to="/sign-out" className="btn btn-primary btn-logpoint-secondary btn-sign-in-out w-75 text-left float-left">
                    <FontAwesomeIcon icon={faSignOutAlt} size="3x" className="h-100 mx-4"/>
                    <span className="pl-2 font-weight-bold text-black position-absolute">Sign Out</span>
                </Link>
            </Col>
        </Row>
    );
};
