import * as React from 'react';
import {withRouter} from 'react-router-dom';

export const BackgroundImage = withRouter(({history}) => {
    const isBackgroundImageHidden = (): boolean => {
        return !Array.from(['/', '/covid-not-permitted', '/final-page']).includes(history.location.pathname);
    };

    return (
        <div className="position-absolute bg-page" hidden={isBackgroundImageHidden()}/>
    )
});
