import * as React from 'react';
import {Button, Card, CardBody, CardFooter, CardText} from 'reactstrap';
import {PageFlowService} from '../../services/PageFlowService';
import {Context} from '../shared/Context';
import {IContext} from '../../models/interfaces/IContext';
import {EventLogService} from '../../services/EventService';

export const CovidHealthDeclarationConfirmation = (props: { history: any }) => {

    const eventLogService = new EventLogService();
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);

    React.useEffect(() => {
        pageFlowService.redirectToWaitScreen(context as IContext, props.history);

        if (context) {
            pageFlowService.redirectToWaitScreenAfterTimeout(context, props.history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickNo = () => {
        if (context) {
            eventLogService.logScreenAbandonedEvent(context, props.history);
            props.history.push('/covid-not-permitted');
        }
    };

    const onClickYes = () => {
        pageFlowService.redirectToNextPage(context as IContext)
            .subscribe(nextPage => props.history.push(nextPage));
    };

    return (
        <Card className="text-left mt-3 w-75 mx-auto border-0">
            <CardBody>
                <CardText className="m-3 default-font-size font-weight-bold" tag={'div'}>
                    <b className="d-inline-block mb-3">Please confirm the following: </b>
                    <ul>
                        <li className="mb-2">You have not developed any flu-like symptoms (e.g. cough, sore throat,
                            fever or shortness of breath, loss of smell/taste) in the past 14 days.
                        </li>
                        <li className="mb-2">You have not been diagnosed with or had close contact to anyone who is
                            confirmed to have COVID-19 in the past 14 days.
                        </li>
                        <li className="mb-2">You are not currently subject to self-isolation directions from the Australian or
                            State/Territory Government for any reason, including close contact, awaiting test results,
                            or international/ inter-state/territory travel.
                        </li>
                        <li>Once you have selected Yes or No, please sanitise this screen</li>
                    </ul>
                </CardText>
            </CardBody>
            <CardFooter className="border-0 px-0">
                <Button type="button" onClick={onClickYes}
                        className="float-right btn-lg btn-logpoint btn-card default-font-size font-weight-bold w-100 mb-3">
                    Yes
                </Button>
                <Button type="button" color="primary" onClick={onClickNo}
                        className="btn-lg btn-logpoint btn-card default-font-size bg-lighter font-weight-bold w-100 text-black">
                    No
                </Button>
            </CardFooter>
        </Card>
    );
};
