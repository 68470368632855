export enum LogpointEvent {

    ABORT_FIT_FOR_WORK = 4,
    ABORT_MESSAGES = 5,
    ABORT_WELCOME = 6,
    ABORT_FIT_FOR_WORK_OFFLINE = 21,
    ABORT_WELCOME_OFFLINE = 22,
    ABORT_MESSAGES_OFFLINE = 23,
    ABORT_FIT_FOR_WORK_OFFLINE_ANON = 24,
    ABORT_INDUCTION_CONFIRMATION = 53,
    ABORT_FLASH_INDUCTION = 56,
    ABORT_GENERAL_PAGE_FLOW = 57,
    ABORT_ALCOLIZER_READING = 61,
    ABORT_CONCO_DATA = 71,
    ABORT_SITES_LOGGED_IN_AT = 72,
    ABORT_FLASH_INDUCTION_ACCESS_ERROR = 81,
    ABORT_PHOTO = 87,
    ABORT_TIMEOUT_LOGOUT_CONFIRM = 88,
    ABORT_CANCEL_LOGOUT_CONFIRM = 89,
    ABORT_FINGERPRINT = 91,

}
