// IMPORTANT: This must only be imported once in the application
import 'reflect-metadata';
// All the CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app.css';
// Script imports
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
//Pegasus
import {BackgroundImage} from './components/shared/BackgroundImage';
import {MainContainer} from './components/shared/MainContainer';
// Font awesome
import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faSignInAlt} from '@fortawesome/pro-light-svg-icons/faSignInAlt';
import {faSignOutAlt} from '@fortawesome/pro-light-svg-icons/faSignOutAlt';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faChevronCircleLeft} from '@fortawesome/pro-light-svg-icons/faChevronCircleLeft';
import {faChevronCircleRight} from '@fortawesome/pro-light-svg-icons/faChevronCircleRight';
import {faHomeAlt} from '@fortawesome/pro-regular-svg-icons/faHomeAlt';
import {faQrcode} from '@fortawesome/pro-light-svg-icons/faQrcode';
import {faFileAlt} from '@fortawesome/pro-light-svg-icons/faFileAlt';
// Context
import {ContextProvider} from './components/shared/Context';

// Register fontawesome icons
config.autoAddCss = true;
library.add(faSignInAlt, faSignOutAlt, faUser, faChevronCircleLeft, faChevronCircleRight, faHomeAlt, faQrcode, faFileAlt);
dom.watch();

export const App = () => {
    return (
        <ContextProvider>
            <HashRouter>
                <BackgroundImage/>
                <MainContainer/>
            </HashRouter>
        </ContextProvider>
    )
};

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('app')
);
