import * as React from 'react';
import {Navbar} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {SoftpointService} from '../../services/SoftpointService';
import {first} from 'rxjs/operators';

export const Header = withRouter(({history}) => {
    const softpointService = new SoftpointService();

    const [name, setName] = React.useState<string>('');

    React.useEffect(() => {
        softpointService.getInfo().pipe(first())
            .subscribe(info => setName(info.name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-header bg-transparent">
            <Navbar light className="h-100 d-block">
                <span className="h-100 d-inline-block lp-name">{name}</span>
            </Navbar>
        </div>
    );
});
