import {combineLatest, Observable, of} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Direction} from '../models/enums/Direction';
import {IContext} from '../models/interfaces/IContext';
import {IPage} from '../models/interfaces/IPage';
import {PageType} from '../models/enums/PageType';
import {EventLogService} from './EventService';

export class PageFlowService {

    private eventLogService = new EventLogService();

    public redirectToNextPage(context: IContext): Observable<string> {
        return this.determineNextPage(context).pipe(first());
    }

    public redirectToWaitScreen(context: IContext, history: any): void {
        if (!context.worker?.id) {
            history.push('/');
        }
    }

    // Modified from an answer found here:
    // https://stackoverflow.com/questions/5631307/redirect-user-after-60-seconds-of-idling-inactivity
    public redirectToWaitScreenAfterTimeout(context: IContext, history: any): void {
        const timeoutDuration = 90000; // 90 seconds
        let idleTimeout = 0;

        const resetIdleTimeout = () => {
            if (idleTimeout && idleTimeout > 0) {
                clearTimeout(idleTimeout);
            }

            idleTimeout = window.setTimeout(() => {
                const exempt = Array.from(['/', '/sign-in', '/sign-out']);
                if (!exempt.some(path => path === history.location.pathname)) {
                    this.eventLogService.logScreenAbandonedEvent(context, history);
                }
                history.push('/');
            }, timeoutDuration * 1000);
        };

        resetIdleTimeout();

        ['click', 'touchstart', 'mousemove']
            .forEach(event => document.addEventListener(event, resetIdleTimeout, false));
    }

    private determineNextPage(context: IContext): Observable<string> {
        return this.getRequiredPagesForWorkerType(context).pipe(
            map(pageChoices => {
                if (pageChoices && pageChoices.length > 0) {
                    for (const page of pageChoices) {
                        if (!context.visitedPages.some(item => item.pageType === page.pageType)) {
                            context.visitedPages.push(page);
                            return page.path;
                        }
                    }
                }
                return '/'
            })
        )
    }

    private getRequiredPagesForWorkerType(context: IContext): Observable<IPage[]> {
        let requiredPages$: Observable<IPage[]>;
        if (context.direction === Direction.ENTRY) {
            // FIXME: Not caching the page flow like in LP8
            requiredPages$ = combineLatest([
                //of({pageType: PageType.COVID_HEALTH_DECLARATION, path: '/covid-health-declaration', order: 0} as IPage),
                of({pageType: PageType.MESSAGES, path: '/messages', order: 1} as IPage),
                of({pageType: PageType.FINAL_PAGE, path: '/final-page', order: 9999} as IPage)
            ]);
        } else if (context.direction === Direction.EXIT) {
            requiredPages$ = of([{pageType: PageType.FINAL_PAGE, path: '/final-page', order: 9999} as IPage]);
        } else {
            requiredPages$ = of([{} as IPage]);
        }

        return requiredPages$.pipe(
            map(pages => {
                const validPages = [] as IPage[];
                pages.forEach(page => {
                    if (page.pageType && page.pageType in PageType) {
                        validPages.push(page);
                    }
                });
                return validPages;
            })
        );
    }

}
