import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {SignInSignOutButtons} from './SignInSignOutButtons';
import {Context} from '../shared/Context';

export const Wait = () => {
    const context = React.useContext(Context);

    React.useEffect(() => {
        context?.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row className="h-100">
                <Col sm={6} className="my-auto">
                    <Col sm={6} className="d-flex h-100 offset-sm-5">
                        <img alt="Company Logo" className="logo"/>
                    </Col>
                </Col>
                <Col sm={6} className="my-auto text-center">
                    <SignInSignOutButtons/>
                </Col>
            </Row>
            <div className="vertical-separator"/>
        </>
    );
};
