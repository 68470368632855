import * as React from 'react';
import {SoftpointService} from '../../services/SoftpointService';
import {first} from 'rxjs/operators';

export const DynamicCss = () => {

    const softpointService = new SoftpointService();

    const [backgroundImageUrl, setBackgroundImageUrl] = React.useState<string>('');
    const [logoImageUrl, setLogoImageUrl] = React.useState<string>('');
    const [primaryColour, setPrimaryColour] = React.useState<string>('');

    React.useEffect(() => {
        softpointService.getStyle().pipe(first())
            .subscribe(style => {
                setBackgroundImageUrl(style.background);
                setLogoImageUrl(style.logo);
                setPrimaryColour(style.style);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const css = `
        .bg-page {
            background-image: url("${backgroundImageUrl}");
        }
        
        .logo {
            content: url("${logoImageUrl}");
        }
        
        .btn-qr-code {
            background-color: ${primaryColour};
        }
        
        .btn-logpoint, .btn-logpoint:not(:disabled):not(.disabled):active, .btn-logpoint:disabled, .btn-logpoint:hover, .btn-logpoint:focus {
            background-color: ${primaryColour};
        }
        
        .btn-transparent, .btn-transparent:disabled, .btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus {
            color: ${primaryColour} !important;
        }
        
        .btn-logpoint-secondary svg {
            color: ${primaryColour};
        }
    `;

    return (
        <>
            <style>{css}</style>
        </>
    );
};
