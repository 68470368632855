import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {HomeButton} from './HomeButton';
import {Main} from '../Main';
import {Footer} from './Footer';
import {Header} from './Header';
import {ReportButton} from './ReportButton';
import {DynamicCss} from '../style/DynamicCss';

export const MainContainer = withRouter(({history}) => {
    const useStyle = (): boolean => {
        return !Array.from(['/', '/covid-not-permitted', '/final-page']).includes(history.location.pathname);
    };

    const style = {
        backgroundColor: '#F6F6F6'
    } as React.CSSProperties;

    return (
        <div className="position-relative vh-100 d-flex flex-column main-container" style={useStyle() ? style : {}}>
            <DynamicCss/>
            <Header/>
            <Main/>
            <Footer/>
            <HomeButton/>
            <ReportButton/>
        </div>
    )
});
