import * as React from 'react';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import {StringUtils} from '../../../shared/StringUtils';

export const WorkerAdvancedLookup = (props: {
    displayIdLookup: boolean, companyNameEl: React.RefObject<HTMLInputElement>, companyNameValue: string,
    setCompanyNameValue: (value: string) => void, firstNameValue: string, setFirstNameValue: (value: string) => void,
    lastNameValue: string, setLastNameValue: (value: string) => void, phoneNumberValue: string,
    setPhoneNumberValue: (value: string) => void, onClickSearch: () => void, displayHits: boolean,
    setLoading: (value: boolean) => void, history: any
}) => {
    const isSearchDisabled = (): boolean => {
        return props.companyNameValue.length < 3 || props.firstNameValue.length < 3;
    }

    const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.setCompanyNameValue(e.target.value);
        if (e.target.value.length === 0) {
            props.setFirstNameValue('');
            props.setLastNameValue('');
            props.setPhoneNumberValue('');
        }
    };

    const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (StringUtils.hasNoNumerals(e.target.value)) {
            props.setFirstNameValue(e.target.value);
            if (e.target.value.length === 0) {
                props.setLastNameValue('');
                props.setPhoneNumberValue('');
            }
        }
    };

    const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (StringUtils.hasNoNumerals(e.target.value)) {
            props.setLastNameValue(e.target.value);
            if (e.target.value.length === 0) {
                props.setPhoneNumberValue('');
            }
        }
    };

    const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (StringUtils.isNumeric(e.target.value) && e.target.value.length <= 11) {
            props.setPhoneNumberValue(e.target.value);
        }
    };

    return (
        <div hidden={props.displayIdLookup || props.displayHits}>
            <Row className="mt-2 mb-3">
                <Col sm="12" className="text-center">
                    <span className="fa-2x font-weight-bold">Enter Your Details</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={9} className="mx-auto">
                    <Label for="companyName" className="mb-3 w-100">
                        <span className="d-block text-left mb-2 font-weight-bold input-label">Company Name</span>
                        <Input innerRef={props.companyNameEl} type="text" name="companyName" autoComplete="off"
                               id="companyName" className="btn-huge logpoint-form-control"
                               placeholder="Company Name" value={props.companyNameValue}
                               onChange={(e) => onChangeCompanyName(e)}/>
                    </Label>
                </Col>
                <Col sm={9} className="mx-auto">
                    <Row>
                        <Col sm={4}>
                            <Label for="name" className="mb-3 w-100">
                                <span className="d-block text-left mb-2 font-weight-bold input-label">First Name</span>
                                <Input type="text" name="name" autoComplete="off" id="name"
                                       className="btn-huge logpoint-form-control" placeholder="First Name"
                                       value={props.firstNameValue} disabled={props.companyNameValue.length < 3}
                                       onChange={(e) => onChangeFirstName(e)}/>
                            </Label>
                        </Col>
                        <Col sm={4}>
                            <Label for="lastName" className="mb-3 w-100">
                                <span className="d-block text-left mb-2 font-weight-bold input-label">Last Name</span>
                                <Input type="text" name="lastName" autoComplete="off" id="lastName"
                                       className="btn-huge logpoint-form-control" placeholder="Last Name"
                                       value={props.lastNameValue} disabled={props.firstNameValue.length < 3}
                                       onChange={(e) => onChangeLastName(e)}/>
                            </Label>
                        </Col>
                        <Col sm={4}>
                            <Label for="phoneNumber" className="mb-3 w-100">
                                <span className="d-block text-left mb-2 font-weight-bold input-label">
                                    Phone Number
                                </span>
                                <Input type="text" name="phoneNumber" autoComplete="off" id="phoneNumber"
                                       className="logpoint-form-control" placeholder="Phone No."
                                       value={props.phoneNumberValue} disabled={props.firstNameValue.length < 3}
                                       onChange={(e) => onChangePhoneNumber(e)} inputMode="numeric" pattern="[0-9]*"/>
                            </Label>
                        </Col>
                    </Row>
                </Col>
                <Col sm={9} className="mx-auto mt-3">
                    <Button className="btn-logpoint p-2 w-100" disabled={isSearchDisabled()}
                            onClick={() => props.onClickSearch()}>
                        <span>Next</span>
                    </Button>
                </Col>
            </Row>
        </div>
    )
};
