import {RestClient} from './RestClient';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {ISoftpointInfo} from '../models/interfaces/ISoftpointInfo';
import {ISoftpointStyle} from '../models/interfaces/ISoftpointStyle';

export class SoftpointService {
    private restClient = new RestClient();

    public getInfo(): Observable<ISoftpointInfo> {
        return fromPromise(this.restClient?.doCallWithResult('/api/v1/softpoint', 'GET') as Promise<any>);
    }

    public getStyle(): Observable<ISoftpointStyle> {
        return fromPromise(this.restClient?.doCallWithResult('/api/v1/softpoint/style',
            'GET') as Promise<any>);
    }

}
