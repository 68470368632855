import {HostService} from './HostService';
import {logger} from '../shared/logging';

export class RestClient {

    private hostService = new HostService();

    public async doCall(endpoint: string, method: string, request?: any): Promise<any> {
        try {
            const rs = await this.doAsyncCall(endpoint, method, request);
            if (rs.ok) {
                return rs;
            }
            return await rs.text();
        } catch (e) {
            return e;
        }
    }

    public async doCallWithResult(endpoint: string, method: string, request?: any): Promise<any> {
        const rs = await this.doAsyncCall(endpoint, method, request);
        if (rs.ok) {
            return rs.json().catch(e => logger.error('Blank or invalid json returned. Error: ', e));
        }
        return Promise.reject(await rs.text());
    }

    private doAsyncCall(endpoint: string, method: string, request?: any): Promise<Response> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            headers.append('X-SSL-Client-Serial', '4502');
        }
        const init: RequestInit = {
            'method': method,
            'headers': headers,
            'mode': 'cors',
            'cache': 'no-cache'
        };
        if (request) {
            if (method.toUpperCase() === 'GET') {
                const requestQueryString = Object.keys(request).map((key: string) => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(request[key])}`
                }).join('&');
                endpoint = `${endpoint}?${requestQueryString}`;
            } else {
                init.body = JSON.stringify(request);
            }
        }
        return fetch(this.hostService.restEndpoint + endpoint, init);
    }

}
