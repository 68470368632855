import * as React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {EventLogService} from '../../services/EventService';
import {Context} from './Context';
import {faHomeAlt} from '@fortawesome/pro-regular-svg-icons/faHomeAlt';

export const HomeButton = withRouter(({history}) => {
    const eventLogService = new EventLogService();

    const context = React.useContext(Context);

    const displayHomeButton = (): boolean => {
        return Array.from(['/', '/final-page']).includes(history.location.pathname);
    };

    const onClick = (): void => {
        if (context) {
            const exempt = Array.from(['/', '/sign-in', '/sign-out']);
            if (context.worker && !exempt.some(path => path === history.location.pathname)) {
                eventLogService.logScreenAbandonedEvent(context, history);
            }

            history.push('/');
        }
    };

    return (
        <Link to="/" className="position-absolute btn-logpoint btn-home" onClick={onClick} hidden={displayHomeButton()}>
            <FontAwesomeIcon icon={faHomeAlt} size="2x" className="d-block mx-auto h-100"/>
        </Link>
    )
});
