import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {PegasusSpinner} from '../shared/PegasusSpinner';
import {PageFlowService} from '../../services/PageFlowService';
import {AccessService} from '../../services/AccessService';
import {Context} from '../shared/Context';
import {IContext} from '../../models/interfaces/IContext';
import {Direction} from '../../models/enums/Direction';
import {delay, first, tap} from 'rxjs/operators';

export const FinalPage = withRouter(({history}) => {
    const manualAccessService = new AccessService();
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);

    const [heading, setHeading] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        pageFlowService.redirectToWaitScreen(context as IContext, history);

        if (context && context.worker) {
            setLoading(true);
            pageFlowService.redirectToWaitScreenAfterTimeout(context, history);

            handleRedirect(context)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = (context: IContext) => {
        const instructions = 'PLACEHOLDER';
        const timeout = 5000;
        handleAccess(context, instructions, timeout).pipe(first())
            .subscribe(_ => {
                history.push('/');
            });
    };

    const handleAccess = (context: IContext, instructions: string, timeout: number) => {
        return manualAccessService.handleAccess(context)
            .pipe(
                first(),
                tap(result => {
                    const direction = context.direction;
                    if (result.canLogin) {
                        if (direction === Direction.ENTRY) {
                            setHeading('You have successfully signed in.');
                        } else if (direction === Direction.EXIT) {
                            setHeading('You have successfully signed out.');
                        }
                        setLoading(false);
                    } else {
                        if (direction === Direction.ENTRY) {
                            setHeading('You have been rejected from signing in.');
                        } else if (direction === Direction.EXIT) {
                            setHeading('You have been rejected from signing out.');
                        }
                        setLoading(false);
                    }
                }),
                delay(timeout)
            );
    };

    return (
        <>
            <div className="h-100 text-center">
                <Row className="h-100">
                    <Col sm={12} className="mt-5">
                        <img alt="Company Logo" className="logo"/>
                        <span className="mt-5 fa-2x d-block font-weight-bold">{heading}</span>
                    </Col>
                </Row>
            </div>
            <PegasusSpinner message="Verifying..." hidden={!loading}/>
        </>
    );
});
