import {Direction} from '../models/enums/Direction';
import {Observable, of} from 'rxjs';
import {IContext} from '../models/interfaces/IContext';
import {IWorker} from '../models/interfaces/worker/IWorker';
import {WorkerService} from './WorkerService';
import {logger} from '../shared/logging';
import {first} from 'rxjs/operators';

export class AccessService {

    private workerService = new WorkerService();

    public handleAccess(context: IContext): Observable<IWorker> {
        if (context.worker?.id) {
            if (context.direction === Direction.ENTRY) {
                return this.workerService.login(context.worker.id);
            } else if (context.direction === Direction.EXIT) {
                return this.workerService.logout(context.worker.id);
            } else {
                logger.warn(`Invalid direction when trying to login. Direction: ${context.direction}`);
                return of();
            }
        } else {
            logger.warn(`Invalid worker ID: ${context.worker?.id}`);
            return of();
        }
    }

    public updateContext(workerId: number, direction: Direction, context: IContext): void {
        context.setDirection(direction);
        this.workerService.getWorkerById(workerId)
            .pipe(first())
            .subscribe(worker => {
                context.setWorker(worker);
            }, error => logger.error('Error while getting worker information', error));
    }

    public validateWorker(context: IContext, history: any, handleRedirect: () => void,
                          setRejectionReason: (value: string) => void, setDeniedAccessModal: (value: boolean) => void,
                          setDirectionUnknownModal?: (value: boolean) => void, setLoading?: (value: boolean) => void): void {
        if (context.direction === Direction.ENTRY) {
            if (context.worker?.canLogin) {
                handleRedirect();
            } else {
                context.reset();
                AccessService.setDeniedAccessModalTrue(context, setRejectionReason, setDeniedAccessModal, setDirectionUnknownModal, setLoading);
            }
        } else if (context.direction === Direction.EXIT) {
            handleRedirect();
        }
    }

    private static setDeniedAccessModalTrue(context: IContext, setRejectionReason: (value: string) => void,
                                            setDeniedAccessModal: (value: boolean) => void,
                                            setDirectionUnknownModal?: (value: boolean) => void,
                                            setLoading?: (value: boolean) => void): void {
        setRejectionReason(context.worker?.rejectionReason as string);
        setDeniedAccessModal(true);
        if (setDirectionUnknownModal) {
            setDirectionUnknownModal(false);
        }
        if (setLoading) {
            setLoading(false);
        }
    }
}
