import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {PageFlowService} from '../../services/PageFlowService';
import {Context} from '../shared/Context';
import {IContext} from '../../models/interfaces/IContext';

export const CovidNotPermitted = withRouter(({history}) => {
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);


    React.useEffect(() => {
        pageFlowService.redirectToWaitScreen(context as IContext, history);

        if (context) {
            pageFlowService.redirectToWaitScreenAfterTimeout(context, history);
        }

        handleRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = (): void => {
        setTimeout(() => {
            history.push('/');
        }, 50000)
    };

    return (
        <div className="h-100 text-center">
            <Row className="h-100">
                <Col sm={12} className="mt-5">
                    <img alt="Company Logo" className="logo"/>
                    <span className="mt-5 fa-2x d-block font-weight-bold">
                        You are not permitted to enter this site.
                    </span>
                    <span className="mt-2 fa-2x d-block font-weight-bold">Please contact your direct leader.</span>
                </Col>
            </Row>
        </div>
    );
});
