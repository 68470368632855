export class StringUtils {

    private static numberRegex = new RegExp('^\\d*$');
    private static stringRegex = new RegExp('^[a-z A-Z]*$');

    public static isNumeric(value: string): boolean {
        return this.numberRegex.test(value);
    }

    public static hasNoNumerals(value: string): boolean {
        return this.stringRegex.test(value) || value === '';
    }

}
