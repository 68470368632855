import * as React from 'react';
import {WorkerHit} from './WorkerHit';
import {Direction} from '../../../models/enums/Direction';
import {first} from 'rxjs/operators';
import {DeniedAccessModal} from '../../shared/DeniedAccessModal';
import {PegasusSpinner} from '../../shared/PegasusSpinner';
import {Button, Carousel, CarouselControl, CarouselItem, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IWorker} from '../../../models/interfaces/worker/IWorker';
import {Context} from '../../shared/Context';
import {AccessService} from '../../../services/AccessService';
import {IContext} from '../../../models/interfaces/IContext';
import {PageFlowService} from '../../../services/PageFlowService';
import {faChevronCircleLeft} from '@fortawesome/pro-light-svg-icons/faChevronCircleLeft';
import {faChevronCircleRight} from '@fortawesome/pro-light-svg-icons/faChevronCircleRight';

export const WorkerHits = (props: {
    foundWorkers: IWorker[], direction: Direction, hidden: boolean, displayHits: boolean,
    setDisplayHits: (value: boolean) => void, history: any
}) => {
    const accessService = new AccessService();
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);

    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const [animating, setAnimating] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [rejectionReason, setRejectionReason] = React.useState('');

    const toggle = (): void => {
        props.setDisplayHits(false);
        setModal(!modal);
    }

    React.useEffect(() => {
        if (context) {
            pageFlowService.redirectToWaitScreenAfterTimeout(context, props.history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (context?.worker) {
            accessService.validateWorker(context, props.history, handleRedirect, setRejectionReason,
                setModal, undefined, setLoading);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context]);

    const displayHits = (): JSX.Element | null => {
        if (props.displayHits) {
            if (props.foundWorkers.length === 0 || (props.foundWorkers.length > 0 && props.foundWorkers[0] === undefined)) {
                return (
                    <>
                        <Col sm={8} className="mx-auto mt-10rem text-center fa-2x">
                            We couldn't find a Worker to match that search.
                        </Col>
                        <Button type="button" onClick={onClickGoBack}
                                className="btn-logpoint p-3 font-weight-bold w-100 col-sm-8 mt-4 mx-auto">
                            <span>Try Again</span>
                        </Button>
                    </>
                );
            } else if (props.foundWorkers.length === 1) {
                const worker = props.foundWorkers[0];
                return (
                    <>
                        <Col sm="12" className="text-center mt-2 mb-3">
                            <span className="fa-2x font-weight-bold">Is This You?</span>
                        </Col>
                        <Col sm={7} key={worker.id}
                             className="hit p-4 mx-auto mt-4 mb-3">
                            <WorkerHit workerName={worker.fullName} workerId={worker.id} phoneNumber={worker.phone}
                                       companyName={worker.companyName}/>
                        </Col>
                        <Button type="button" color="secondary" onClick={() => onClickHit(worker)}
                                className="btn-logpoint p-3 font-weight-bold w-100 col-sm-7 mt-4 mx-auto">
                            <span>Yes, This Is Me</span>
                        </Button>
                        <Button type="button" color="secondary" onClick={onClickGoBack}
                                className="btn-logpoint bg-lighter p-3 font-weight-bold w-100 col-sm-7 mt-4 mx-auto">
                            <span className="text-black">Go Back</span>
                        </Button>
                    </>
                );
            } else {
                const slides = props.foundWorkers.map(worker => {
                    return (
                        <CarouselItem onExiting={() => setAnimating(true)}
                                      onExited={() => setAnimating(false)} key={worker.id}>
                            <Col sm={7} key={worker.id} className="hit p-4 mx-auto mt-5 mb-3">
                                <WorkerHit workerName={worker.fullName} workerId={worker.id} phoneNumber={worker.phone}
                                           companyName={worker.companyName}/>
                            </Col>
                        </CarouselItem>
                    );
                })

                return (
                    <>
                        <Col sm="12" className="text-center mt-2 mb-3">
                            <span className="fa-2x font-weight-bold">We found {props.foundWorkers.length} results</span>
                        </Col>
                        <Carousel interval={false} activeIndex={activeIndex} next={next} previous={previous}
                                  className="w-100">
                            {slides}
                            <div>
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}
                                                 className="mt-5"/>
                                <FontAwesomeIcon icon={faChevronCircleLeft} size="4x"
                                                 className="carousel-control text-black h-100 mx-5 position-absolute"/>
                            </div>
                            <div>
                                <CarouselControl direction="next" directionText="Next" onClickHandler={next}
                                                 className="mt-5"/>
                                <FontAwesomeIcon icon={faChevronCircleRight} size="4x"
                                                 className="carousel-control carousel-control-right text-black h-100 mx-5 position-absolute"/>
                            </div>
                        </Carousel>
                        <Button type="button" color="secondary"
                                onClick={() => onClickHit(props.foundWorkers[activeIndex])}
                                className="btn-logpoint p-3 font-weight-bold w-100 col-sm-7 mt-4 mx-auto">
                            <span>Yes, This Is Me</span>
                        </Button>
                        <Button type="button" color="secondary" onClick={onClickGoBack}
                                className="btn-logpoint bg-lighter p-3 font-weight-bold w-100 col-sm-7 mt-4 mx-auto">
                            <span className="text-black">Go Back</span>
                        </Button>
                    </>
                );
            }
        }
        return null;
    }

    const handleRedirect = (): void => {
        pageFlowService.redirectToNextPage(context as IContext).pipe(first())
            .subscribe(nextPage => {
                setLoading(false);
                props.history.push(nextPage);
            }, err => {
                setLoading(false);
                setRejectionReason(`Error: ${err}`);
                setModal(true);
            });
    };

    const next = (): void => {
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === props.foundWorkers.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const onClickGoBack = (): void => {
        setActiveIndex(0);
        props.setDisplayHits(false);
    };

    const onClickHit = (worker: IWorker): void => {
        if (context) {
            setLoading(true);
            accessService.updateContext(worker.id, props.direction, context);
        }
    };

    const previous = (): void => {
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? props.foundWorkers.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    return (
        <div hidden={props.hidden}>
            <Row>
                {displayHits()}
            </Row>
            <DeniedAccessModal isOpen={modal} toggle={toggle} message={rejectionReason}/>
            <PegasusSpinner hidden={!loading} message="Verifying..."/>
        </div>
    );
};
