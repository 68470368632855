import * as React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileAlt} from '@fortawesome/pro-light-svg-icons';

export const ReportButton = withRouter(({history}) => {
    const displayReportButton = (): boolean => {
        return !Array.from(['/']).includes(history.location.pathname);
    };

    return (
        <Link to="/onsite-now" className="position-absolute btn-logpoint btn-report" hidden={displayReportButton()}>
            <FontAwesomeIcon icon={faFileAlt} size="2x" className="d-block mx-auto h-100"/>
        </Link>
    )
});
