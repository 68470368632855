import sanitizeHtml from 'sanitize-html';
import {IDangerouslySetInnerHtml} from '../models/interfaces/IDangerouslySetInnerHtml';

export class HtmlSanitisationService {

    private _options = {
        allowedTags: [
            'abbr', 'b', 'br', 'caption', 'code', 'div', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'li',
            'ol', 'p', 'pre', 's', 'strong', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'ul'
        ],
        allowedAttributes: {
            'abbr': ['title'], 'caption': ['style'], 'code': ['style'], 'div': ['style'], 'h1': ['style'],
            'h2': ['style'], 'h3': ['style'], 'h4': ['style'], 'h5': ['style'], 'h6': ['style'], 'hr': ['style'],
            'li': ['style', 'value'], 'ol': ['reversed', 'start', 'style', 'type'], 'p': ['style'], 'pre': ['style'],
            'table': ['style'], 'tbody': ['style'], 'td': ['colspan', 'headers', 'rowspan', 'style'],
            'tfoot': ['style'], 'th': ['abbr', 'colspan', 'headers', 'rowspan', 'scope', 'style'], 'thead': ['style'],
            'tr': ['style'], 'ul': ['style']
        },
        selfClosing: ['br', 'hr'],
        // Disallowed tags are discarded, while any text or allowed tags inside it are kept.
        disallowedTagsMode: 'discard',
        // Ensure we're not just looking for text inside a <html> tag
        enforceHtmlBoundary: false,
        // Don't allow any URL schemes on any attributes
        allowedSchemes: [],
        allowedSchemesAppliedToAttributes: [],
        allowProtocolRelative: false
    } as sanitizeHtml.IOptions;

    public sanitiseHtml(html: string): IDangerouslySetInnerHtml {
        return {__html: sanitizeHtml(html, this._options)} as IDangerouslySetInnerHtml;
    };

}
