import {RestClient} from './RestClient';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {ISearchCriteria} from '../models/interfaces/ISearchCriteria';
import {IObfuscatedWorkerDetails} from '../models/interfaces/IObfuscatedWorkerDetails';
import {IWorker} from '../models/interfaces/worker/IWorker';
import {IPhotoLink} from '../models/interfaces/IPhotoLink';
import {IEvacuation} from '../models/interfaces/IEvacuation';
import {SortBy} from '../models/enums/Logpoint';

export class WorkerService {
    private restClient = new RestClient();

    public getWorkerById(workerId: number): Observable<IWorker> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/worker/${workerId}`,
            'GET') as Promise<any>);
    }

    public getWorkerImage(workerId: number): Observable<IPhotoLink> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/worker/${workerId}/photo`,
            'GET') as Promise<any>);
    }

    public getWorkers(searchRequest: ISearchCriteria): Observable<IWorker[]> {
        return fromPromise(this.restClient?.doCallWithResult('/api/v1/softpoint/search', 'POST',
            searchRequest) as Promise<any>);
    }

    public login(workerId: number): Observable<IWorker> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/login/${workerId}`,
            'GET') as Promise<any>);
    }

    public logout(workerId: number): Observable<IWorker> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/logout/${workerId}`,
            'GET') as Promise<any>);
    }

    public onsiteNowReport(showMoves?: string, sortBy?: SortBy): Observable<IEvacuation[]> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/evacuation`,
            'GET') as Promise<any>);
    }

    public obfuscateWorkerDetails(workerId: string, phoneNumber: string): IObfuscatedWorkerDetails {
        return {
            'workerId': WorkerService.obfuscateWorkerId(workerId),
            'phoneNumber': WorkerService.obfuscatePhoneNumber(phoneNumber)
        } as IObfuscatedWorkerDetails;
    }

    private static obfuscateWorkerId(value: string): string {
        if (value) {
            if (value.length > 2 && value.length <= 5) {
                value = this.obfuscateString(value, 1, 1);
            } else if (value.length > 5) {
                value = this.obfuscateString(value, 2, 2);
            }
        }
        return value;
    }

    private static obfuscatePhoneNumber(value: string): string {
        if (value) {
            if (value.length > 2 && value.length <= 5) {
                value = this.obfuscateString(value, 1, 1);
            } else if (value.length > 5) {
                value = this.obfuscateString(value, 4, 2);
            }
        }
        return value;
    }

    private static obfuscateString(value: string, firstDigits: number, lastDigits: number): string {
        const first = value.slice(0, firstDigits);
        const middle = value.slice(firstDigits, -lastDigits);
        const last = value.slice(-lastDigits);
        return first + middle.replace(/\d/g, '*') + last;
    }
}
