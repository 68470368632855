import * as React from 'react';
import {Button, Col, Collapse, Input, InputGroup, InputGroupAddon, Label, Row} from 'reactstrap';
import {StringUtils} from '../../../shared/StringUtils';
import {Direction} from '../../../models/enums/Direction';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQrcode} from '@fortawesome/pro-light-svg-icons/faQrcode'
import {QRScanner} from '../../qr-scanner/QRScanner';

export const WorkerIdLookup = (props: {
    displayIdLookup: boolean, setDisplayIdLookup: (value: boolean) => void, onClickSearch: () => void,
    workerIdEl: React.RefObject<HTMLInputElement>, workerIdValue: string, setWorkerIdValue: (value: string) => void,
    displayHits: boolean, displayQrScanner: boolean, setDisplayQrScanner: (value: boolean) => void,
    direction: Direction, history: any
}) => {
    const [cameraOn, setCameraOn] = React.useState<boolean>(false);

    const displayTryAnotherWay = (): string => {
        return props.direction === Direction.ENTRY ? 'Sign In Another Way' : 'Sign Out Another Way';
    };

    const displayQrScanner = (): JSX.Element | null => {
        if (cameraOn) {
            return (
                <QRScanner workerIdValue={props.workerIdValue} setWorkerIdValue={props.setWorkerIdValue}
                           displayQrScanner={props.displayQrScanner} setDisplayQrScanner={props.setDisplayQrScanner}
                           onClickSearch={props.onClickSearch}/>
            );
        }
        return null;
    };

    const onChangeWorkerId = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (StringUtils.isNumeric(e.target.value)) {
            props.setWorkerIdValue(e.target.value);
        }
    };

    const onClickForgotId = (): void => {
        props.setWorkerIdValue('');
        props.setDisplayIdLookup(false);
    };

    const onClickQrCode = (): void => {
        const displayQr = props.displayQrScanner;
        if (!displayQr) {
            setCameraOn(true);
            props.setWorkerIdValue('');
            props.setDisplayQrScanner(true);
        } else {
            props.setDisplayQrScanner(false);
        }
    };

    return (
        <div hidden={!props.displayIdLookup || props.displayHits}>
            <Row className="mt-2 mb-3">
                <Col sm="12" className="text-center">
                    <span className="fa-2x font-weight-bold">Enter Your Worker ID</span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} className="text-center mb-3">
                    <Label for="workerId" className="w-50">
                        <span className="d-block text-left mb-2 font-weight-bold input-label">Worker ID</span>
                        <InputGroup>
                            <Input innerRef={props.workerIdEl} type="text" name="workerId" autoComplete="off"
                                   id="workerId" className="logpoint-form-control" inputMode="numeric" pattern="[0-9]*"
                                   placeholder="Worker ID" value={props.workerIdValue}
                                   disabled={props.displayQrScanner} onChange={onChangeWorkerId}/>
                            <InputGroupAddon addonType="append">
                                <Button onClick={onClickQrCode} className="btn-logpoint btn-qr-code">
                                    <FontAwesomeIcon icon={faQrcode} size="2x" className="d-block mx-auto h-100"/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Label>
                </Col>
                <Col sm={12}>
                    <Collapse className="pb-4" isOpen={props.displayQrScanner} onExited={() => setCameraOn(false)}>
                        {displayQrScanner()}
                    </Collapse>
                </Col>
                <Col sm={12} className="d-flex justify-content-center mb-3">
                    <Button className="btn-logpoint p-2 w-50" disabled={props.workerIdValue.length < 3}
                            onClick={props.onClickSearch}>
                        <span>Next</span>
                    </Button>
                </Col>
                <Col sm={12} className="d-flex justify-content-center">
                    <Button type="button" className="btn-logpoint btn-transparent bg-transparent border-0 p-2 w-35"
                            onClick={onClickForgotId}>
                        <span>{displayTryAnotherWay()}</span>
                    </Button>
                </Col>
            </Row>
        </div>
    )
};
