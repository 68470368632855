import * as React from 'react';
import {Card, CardBody, Table} from 'reactstrap';
import {WorkerService} from '../../services/WorkerService';
import {IEvacuation} from '../../models/interfaces/IEvacuation';
import {PegasusSpinner} from '../shared/PegasusSpinner';

export const OnsiteNowReport = () => {
    const workerService = new WorkerService();

    const [evacuations, setEvacuations] = React.useState<IEvacuation[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        workerService.onsiteNowReport().subscribe(evacs => {
            // Display newest records at the top
            setEvacuations(evacs.sort((a, b) => (a.timeIn < b.timeIn) ? 1 : -1))
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateTimeOnSite = (timeIn: Date): string => {
        const lastSiteLogin = new Date(new Date(timeIn.toString()).toISOString()).valueOf();

        const millisSinceLogIn = Date.now().valueOf() - lastSiteLogin;

        const totalMinutes = Math.floor(millisSinceLogIn / (1000 * 60));
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);

        const minutesRemainder = totalMinutes % 60;
        const hoursRemainder = totalHours % 24;

        if (totalDays > 0) {
            if (totalDays === 1) {
                return 'a day ago'
            }
            return `${totalDays} days ago`;
        } else if (hoursRemainder > 0) {
            if (hoursRemainder === 1) {
                return 'an hour ago';
            }
            return `${hoursRemainder} hours ago`;
        } else {
            if (minutesRemainder === 0) {
                return 'moments ago';
            } else if (minutesRemainder === 1) {
                return 'a minute ago';
            }
            return `${minutesRemainder} minutes ago`;
        }
    };

    const displayDateTime = (dateTime: string): string => {
        // Format of name is <Date>, <Time>
        const date = dateTime.slice(0, dateTime.indexOf(','));
        const time = dateTime.slice(dateTime.indexOf(',') + 2);
        return `${time} (${date})`;
    }

    const displayName = (name: string): string => {
        // Format of name is <Last Name>, <First Name>
        const firstName = name.slice(name.indexOf(',') + 2);
        const lastName = name.slice(0, name.indexOf(','));
        return `${firstName} ${lastName}`;
    }

    const displayPhoneNumber = (phone: string): string => {
        if (phone && phone !== '&nbsp;') {
            return phone;
        }
        return 'N/A';
    }

    const formatDate = (date: Date): string => {
        const newDate = new Date(date.toString());
        return Intl.DateTimeFormat("en-AU", {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
        }).format(newDate);
    }

    return (
        <>
            <Card>
                <CardBody className="onsite-now-report p-0">
                    <Table className="table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Worker ID</th>
                            <th>Worker</th>
                            <th>Type</th>
                            <th>Company</th>
                            <th>Phone</th>
                            <th>Login Time</th>
                            <th>Ago</th>
                        </tr>
                        </thead>
                        <tbody>
                        {evacuations.map(evacuation => {
                            return (
                                <tr key={evacuation.person.id}>
                                    <td className="worker-id">{evacuation.person.id}</td>
                                    <td className="person-name">{displayName(evacuation.person.name)}</td>
                                    <td className="text-nowrap">{evacuation.personType}</td>
                                    <td className="company-name">{evacuation.companyName}</td>
                                    <td className="text-nowrap">{displayPhoneNumber(evacuation.phone)}</td>
                                    <td className="text-nowrap">{displayDateTime(formatDate(evacuation.timeIn))}</td>
                                    <td className="text-nowrap">{calculateTimeOnSite(evacuation.timeIn)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <PegasusSpinner message="Loading..." hidden={!loading}/>
        </>
    );
};
