import * as React from 'react';
import {WorkerHits} from './WorkerHits';
import {Direction} from '../../../models/enums/Direction';
import {combineLatest, of} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {ISearchCriteria} from '../../../models/interfaces/ISearchCriteria';
import {WorkerIdLookup} from './WorkerIdLookup';
import {WorkerAdvancedLookup} from './WorkerAdvancedLookup';
import {PegasusSpinner} from '../../shared/PegasusSpinner';
import {IWorker} from '../../../models/interfaces/worker/IWorker';
import {WorkerService} from '../../../services/WorkerService';
import {logger} from '../../../shared/logging';

export const WorkerSelect = (props: { direction: Direction, history: any }) => {

    const workerService = new WorkerService();

    const [companyNameValue, setCompanyNameValue] = React.useState<string>('');
    const [displayIdLookup, setDisplayIdLookup] = React.useState<boolean>(true);
    const [displayHits, setDisplayHits] = React.useState<boolean>(false);
    const [displayQrScanner, setDisplayQrScanner] = React.useState<boolean>(false);
    const [firstNameValue, setFirstNameValue] = React.useState<string>('');
    const [hits, setHits] = React.useState<IWorker[]>([]);
    const [lastNameValue, setLastNameValue] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [phoneNumberValue, setPhoneNumberValue] = React.useState<string>('');
    const [workerIdValue, setWorkerIdValue] = React.useState<string>('');

    const companyNameEl = React.useRef<HTMLInputElement>(null);
    const workerIdEl = React.useRef<HTMLInputElement>(null);

    const onClickSearch = () => {
        combineLatest([
            of(workerIdValue),
            of(companyNameValue),
            of(firstNameValue),
            of(lastNameValue),
            of(phoneNumberValue)
        ]).pipe(
            first(),
            tap(() => {
                setLoading(true);
            }),
            switchMap(([workerId, companyName, firstName, lastName, phoneNumber]) => {
                if (displayIdLookup) {
                    const searchRequest = {
                        workerId: workerId,
                    } as ISearchCriteria;
                    return workerService.getWorkers(searchRequest);
                } else {
                    const searchRequest = {
                        'companyName': companyName,
                        'firstName': firstName,
                        'lastName': lastName,
                        'phoneNumber': phoneNumber,
                    } as ISearchCriteria;
                    return workerService.getWorkers(searchRequest);
                }
            })
        ).subscribe(results => {
            setDisplayHits(true);
            setLoading(false);
            if (Array.isArray(results)) {
                setHits(results);
            } else {
                setHits(Array.from([results]));
            }
        }, err => logger.error('Error while searching for workers', err));
    };

    return (
        <>
            <WorkerIdLookup displayIdLookup={displayIdLookup} setDisplayIdLookup={setDisplayIdLookup}
                            onClickSearch={onClickSearch} workerIdEl={workerIdEl} workerIdValue={workerIdValue}
                            setWorkerIdValue={setWorkerIdValue} displayHits={displayHits}
                            displayQrScanner={displayQrScanner} setDisplayQrScanner={setDisplayQrScanner}
                            direction={props.direction} history={props.history}/>
            <WorkerAdvancedLookup displayIdLookup={displayIdLookup} companyNameEl={companyNameEl}
                                  companyNameValue={companyNameValue} setCompanyNameValue={setCompanyNameValue}
                                  firstNameValue={firstNameValue} setFirstNameValue={setFirstNameValue}
                                  lastNameValue={lastNameValue} setLastNameValue={setLastNameValue}
                                  phoneNumberValue={phoneNumberValue} setPhoneNumberValue={setPhoneNumberValue}
                                  onClickSearch={onClickSearch} displayHits={displayHits} setLoading={setLoading}
                                  history={props.history}/>
            <WorkerHits foundWorkers={hits} direction={props.direction} history={props.history}
                        hidden={loading || !displayHits} displayHits={displayHits} setDisplayHits={setDisplayHits}/>
            <PegasusSpinner hidden={!loading} message="Searching..."/>
        </>
    )
};
