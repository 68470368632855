import * as React from 'react';
import {logger} from '../../shared/logging';
import QrReader from 'react-qr-reader';

export const QRScanner = (props: {
    workerIdValue: string, setWorkerIdValue: (value: string) => void, displayQrScanner: boolean,
    setDisplayQrScanner: (value: boolean) => void, onClickSearch: () => void
}) => {

    const regex = new RegExp('^i=(\\d*)$|^i=(\\d*)&$|^i=(\\d*)&d=(\\d*)-\\d');

    React.useEffect(() => {
        if (props.displayQrScanner && props.workerIdValue) {
            props.onClickSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workerIdValue]);

    const handleScan = (result: string | null) => {
        if (props.displayQrScanner && result) {
            const controlGroups = result.match(regex);
            if (controlGroups) {
                // Control group 0 will always match `result`,
                // so if the result matches ^i=(\d*)&$ then use the control group 1
                // otherwise if the result matches ^i=(\d*)&d=(\d*)-\d$ then use control group 2
                if (controlGroups[1]) {
                    props.setWorkerIdValue(controlGroups[1]);
                } else if (controlGroups[2]) {
                    props.setWorkerIdValue(controlGroups[2]);
                } else if (controlGroups[3]) {
                    props.setWorkerIdValue(controlGroups[3]);
                }
                props.setDisplayQrScanner(false);
            }
        }
    }

    const handleError = (error: any) => {
        logger.error('Unable to scan QR code', error);
    }

    return (
        <QrReader className="mx-auto"
                  delay={0}
                  resolution={1200}
                  onScan={handleScan}
                  onError={handleError}
                  facingMode="user"
                  style={{width: '16rem'}}/>
    );
};
