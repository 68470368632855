import {RestClient} from './RestClient';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {ICompanyMessage} from '../models/interfaces/message/ICompanyMessage';
import {ISiteMessage} from '../models/interfaces/message/ISiteMessage';
import {IWorkerMessage} from '../models/interfaces/message/IWorkerMessage';

export class MessageService {
    private restClient = new RestClient();

    public getCompanyMessages(companyId: number): Observable<ICompanyMessage[]> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/messages/company/${companyId}`,
            'GET') as Promise<any>);
    }

    public getSiteMessages(): Observable<ISiteMessage[]> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/messages/site`,
            'GET') as Promise<any>);
    }

    public getWorkerMessages(workerId: number): Observable<IWorkerMessage[]> {
        return fromPromise(this.restClient?.doCallWithResult(`/api/v1/softpoint/messages/worker/${workerId}`,
            'GET') as Promise<any>);
    }

}
