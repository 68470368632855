import * as React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {HtmlSanitisationService} from '../../services/HtmlSanitisationService';

export const DeniedAccessModal = (props: { isOpen: boolean, toggle: () => void, message: string }) => {
    const htmlSanitisationService = new HtmlSanitisationService();

    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()} backdrop="static" centered={true} fade={true}>
            <ModalHeader toggle={() => props.toggle()} className="font-main-colour" tag="div">
                <div className="fa-2x">Sorry</div>
            </ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={htmlSanitisationService.sanitiseHtml(props.message)}/>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="primary" className="btn-logpoint btn-modal btn-lg w-100"
                        onClick={() => props.toggle()}>
                    <div className="px-2">Ok</div>
                </Button>
            </ModalFooter>
        </Modal>
    );
};
