import * as React from 'react';
import {IContext} from '../../models/interfaces/IContext';
import {Direction} from '../../models/enums/Direction';
import {IWorker} from '../../models/interfaces/worker/IWorker';

export const Context = React.createContext<IContext | null>(null);

export class ContextProvider extends React.Component {
    public state = {
        worker: undefined,
        direction: Direction.ENTRY,
        visitedPages: []
    };

    public render() {
        return (
            <Context.Provider value={{
                worker: this.state.worker,
                direction: this.state.direction,
                visitedPages: this.state.visitedPages,
                setWorker: (value: IWorker) => this.setState({worker: value}),
                setDirection: (value: Direction) => this.setState({direction: value}),
                reset: () => this.setState({worker: undefined, direction: Direction.ENTRY, visitedPages: []})
            }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
