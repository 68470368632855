import * as React from 'react';
import {Button, CardBody, CardFooter, CardHeader, CardText} from 'reactstrap';
import {IWorkerMessage} from '../../models/interfaces/message/IWorkerMessage';
import {ISiteMessage} from '../../models/interfaces/message/ISiteMessage';
import {PageFlowService} from '../../services/PageFlowService';
import {first} from 'rxjs/operators';
import {HtmlSanitisationService} from '../../services/HtmlSanitisationService';
import {Context} from '../shared/Context';

export const SiteMessages = (props: {
    siteMessages: ISiteMessage[], displaySiteMessages: boolean, setDisplaySiteMessages: (value: boolean) => void,
    workerMessages: IWorkerMessage[], setDisplayWorkerMessages: (value: boolean) => void, currentIndex: number,
    setCurrentIndex: (value: number) => void, history: any
}) => {
    const htmlSanitisationService = new HtmlSanitisationService();
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);

    const handleMessageAcknowledgment = () => {
        if (context) {
            if (props.currentIndex + 1 < props.siteMessages.length) {
                props.setCurrentIndex(props.currentIndex + 1);
            } else {
                props.setDisplaySiteMessages(false);
                props.setCurrentIndex(0);
                if (props.workerMessages?.length > 0) {
                    props.setDisplayWorkerMessages(true);
                } else {
                    pageFlowService.redirectToNextPage(context).pipe(first())
                        .subscribe(nextPage => props.history.push(nextPage));
                }
            }
        }
    };

    const getMessage = (): string => {
        if (props.siteMessages[props.currentIndex]) {
            return props.siteMessages[props.currentIndex].message.message;
        }
        return '';
    };

    return (
        <div hidden={!props.displaySiteMessages}>
            <CardHeader className="border-0">
                (Site Message)
            </CardHeader>
            <CardBody className="bg-white min-height-10rem">
                <CardText tag={'div'} dangerouslySetInnerHTML={htmlSanitisationService.sanitiseHtml(getMessage())}/>
            </CardBody>
            <CardFooter className="px-0 border-0">
                <div className="w-100 mb-3 text-center">
                    <div>{props.currentIndex + 1} of {props.siteMessages ? props.siteMessages.length : 0}</div>
                </div>
                <Button type="button" className="btn-lg btn-logpoint btn-card font-weight-bold w-100"
                        onClick={handleMessageAcknowledgment}>
                    Acknowledge
                </Button>
            </CardFooter>
        </div>
    );
};
