export class HostService {
    private readonly _restEndpoint: string;

    public constructor() {
        const overridePort = undefined; // 8080;
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = overridePort ? overridePort : window.location.port;
        let context = window.location.pathname;

        if (context === '/') {
            context = '';
        }

        const suffix = `${port}${context}`.replace(/\/+$/, '');
        this._restEndpoint = `${protocol}//${hostname}:${suffix}`;
    }

    public get restEndpoint(): string {
        return this._restEndpoint;
    }

}
