import * as React from 'react';
import {Card} from 'reactstrap';
import {PageFlowService} from '../../services/PageFlowService';
import {first} from 'rxjs/operators';
import {IWorkerMessage} from '../../models/interfaces/message/IWorkerMessage';
import {ICompanyMessage} from '../../models/interfaces/message/ICompanyMessage';
import {ISiteMessage} from '../../models/interfaces/message/ISiteMessage';
import {CompanyMessages} from './CompanyMessages';
import {combineLatest} from 'rxjs';
import {SiteMessages} from './SiteMessages';
import {WorkerMessages} from './WorkerMessages';
import {Context} from '../shared/Context';
import {MessageService} from '../../services/MessageService';
import {IContext} from '../../models/interfaces/IContext';

export const Message = (props: { loading: boolean, setLoading: (value: boolean) => void, history: any }) => {
    const messageService = new MessageService();
    const pageFlowService = new PageFlowService();

    const context = React.useContext(Context);

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [displayCompanyMessages, setDisplayCompanyMessages] = React.useState<boolean>(false);
    const [displaySiteMessages, setDisplaySiteMessages] = React.useState<boolean>(false);
    const [displayWorkerMessages, setDisplayWorkerMessages] = React.useState<boolean>(false);
    const [readyToCheck, setReadyToCheck] = React.useState<boolean>(false);
    const [companyMessages, setCompanyMessages] = React.useState<ICompanyMessage[]>([]);
    const [siteMessages, setSiteMessages] = React.useState<ISiteMessage[]>([]);
    const [workerMessages, setWorkerMessages] = React.useState<IWorkerMessage[]>([]);

    React.useEffect(() => {
        pageFlowService.redirectToWaitScreen(context as IContext, props.history);

        if (context) {
            pageFlowService.redirectToWaitScreenAfterTimeout(context, props.history);

            if (context.worker?.id) {
                combineLatest([
                    messageService.getCompanyMessages(context.worker.companyId),
                    messageService.getSiteMessages(),
                    messageService.getWorkerMessages(context.worker.id)
                ])
                    .pipe(first())
                    .subscribe(([company, site, worker]) => {
                        if (company.length > 0) {
                            setCompanyMessages(company);
                        }
                        if (site.length > 0) {
                            setSiteMessages(site);
                        }
                        if (worker.length > 0) {
                            setWorkerMessages(worker);
                        }
                        setReadyToCheck(true);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (readyToCheck) {
            let hasMessages = false;
            if (companyMessages.length > 0) {
                setDisplayCompanyMessages(true);
                hasMessages = true;
            } else if (siteMessages.length > 0) {
                setDisplaySiteMessages(true);
                hasMessages = true;
            } else if (workerMessages.length > 0) {
                setDisplayWorkerMessages(true);
                hasMessages = true;
            } else {
                pageFlowService.redirectToNextPage(context as IContext).pipe(first())
                    .subscribe(nextPage => props.history.push(nextPage));
            }

            if (hasMessages) {
                props.setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyToCheck]);

    return (
        <>
            <Card className="text-left w-75 mx-auto mt-5 font-weight-bold default-font-size border-0"
                  hidden={props.loading}>
                <CompanyMessages companyMessages={companyMessages} displayCompanyMessages={displayCompanyMessages}
                                 setDisplayCompanyMessages={setDisplayCompanyMessages} siteMessages={siteMessages}
                                 setDisplaySiteMessages={setDisplaySiteMessages} workerMessages={workerMessages}
                                 setDisplayWorkerMessages={setDisplayWorkerMessages} currentIndex={currentIndex}
                                 setCurrentIndex={setCurrentIndex} history={props.history}/>
                <SiteMessages siteMessages={siteMessages} displaySiteMessages={displaySiteMessages}
                              setDisplaySiteMessages={setDisplaySiteMessages} workerMessages={workerMessages}
                              setDisplayWorkerMessages={setDisplayWorkerMessages} currentIndex={currentIndex}
                              setCurrentIndex={setCurrentIndex} history={props.history}/>
                <WorkerMessages workerMessages={workerMessages} displayWorkerMessages={displayWorkerMessages}
                                setDisplayWorkerMessages={setDisplayWorkerMessages} currentIndex={currentIndex}
                                setCurrentIndex={setCurrentIndex} history={props.history}/>
            </Card>
        </>
    );
};
