import * as React from 'react';
import {Col, Media, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IObfuscatedWorkerDetails} from '../../../models/interfaces/IObfuscatedWorkerDetails';
import {WorkerService} from '../../../services/WorkerService';
import {first} from 'rxjs/operators';
import {IPhotoLink} from '../../../models/interfaces/IPhotoLink';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';

export const WorkerHit = (props: {
    workerName: string, workerId: number, phoneNumber: string, companyName: string
}) => {
    const workerService = new WorkerService();

    const [imageError, setImageError] = React.useState<boolean>(false);
    const [imageUrl, setImageUrl] = React.useState<IPhotoLink>({} as IPhotoLink);
    const [image, setImage] = React.useState<JSX.Element | null>(null);
    const [obfuscatedWorkerDetails, setObfuscatedWorkerDetails] = React.useState<IObfuscatedWorkerDetails>({} as IObfuscatedWorkerDetails);

    React.useEffect(() => {
        workerService.getWorkerImage(props.workerId).pipe(first())
            .subscribe(photoLink => setImageUrl(photoLink));

        setObfuscatedWorkerDetails(workerService.obfuscateWorkerDetails(props.workerId.toString(), props.phoneNumber));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setImage(
            <FontAwesomeIcon size="6x" icon={faUser} className="worker-image worker-silhouette h-100 mr-3"/>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageError]);

    React.useEffect(() => {
        if (imageUrl.link) {
            setImage(
                <Media object className="h-100 mr-3 worker-image" src={imageUrl.link}
                       onError={() => setImageError(true)}/>
            )
        } else {
            setImage(
                <FontAwesomeIcon size="6x" icon={faUser} className="worker-image worker-silhouette h-100 mr-3"/>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    return (
        <Media body className="h-100">
            {image}
            <Row className="h-100 worker-details d-inline-block">
                <Col sm={10}>
                    <Row>
                        <Col sm={12}>
                            <span className="hit-worker-name font-weight-bold">{props.workerName}</span>
                        </Col>
                        <Col sm={12}>
                            <span className="hit-company-name font-weight-bold">{props.companyName}</span>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <div className="px-2 py-1 obfuscated-details">
                                {obfuscatedWorkerDetails.workerId}
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3" hidden={!obfuscatedWorkerDetails.phoneNumber}>
                            <div className="px-2 py-1 obfuscated-details">
                                {obfuscatedWorkerDetails.phoneNumber}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Media>
    )
};
