import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {Message} from './Message';
import {withRouter} from 'react-router-dom';
import {PegasusSpinner} from '../shared/PegasusSpinner';

export const Messages = withRouter(({history}) => {
    const [loading, setLoading] = React.useState<boolean>(true);

    return (
        <>
            <div className="mt-2 text-center" hidden={loading}>
                <Row>
                    <Col sm="12">
                        <span className="fa-2x font-weight-bold">Messages</span>
                    </Col>
                    <Col sm="12">
                        <span className="sub-heading">You have unread messages</span>
                    </Col>
                </Row>
                <Message history={history} loading={loading} setLoading={setLoading}/>
            </div>
            <PegasusSpinner message="Loading..." hidden={!loading}/>
        </>
    );
});
