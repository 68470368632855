import * as React from 'react';
import {Col, Row} from 'reactstrap';
import {CovidHealthDeclarationConfirmation} from './CovidHealthDeclarationConfirmation';
import {withRouter} from 'react-router-dom';

export const CovidHealthDeclaration = withRouter(({history}) => {
    return (
        <div className="mt-2 text-center">
            <Row>
                <Col sm="12">
                    <span className="fa-2x font-weight-bold">COVID-19 Health Declaration</span>
                </Col>
                <Col sm="12">
                    <span className="sub-heading">ALDI Stores</span>
                </Col>
            </Row>
            <CovidHealthDeclarationConfirmation history={history}/>
        </div>
    );
});
